.mobileBackground{
    display: none;
}

.TopPageLeft{
    width: 50%;
    float: left;
}
.TopPageBackground{
    position: relative;
    left: 43px;
}
.TopPageEmpty{
    width: 100%;
    height: 138px;
}
.TopPageH2{
    color: #D2D2D2;
    font-size: 51px;
}
#TopPageH22{
    color: #D2D2D2;
    font-size: 49px;
    margin-top: 100px;
}
.TopPageH3{
    color:#D2D2D2;
    font-size: 18px;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 50px;
    line-height: 28px;
}
.TopPageTextContainer{
    float: right;
    
}
.TopPageButton{
    width: 232px;
    height: 48px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 800;
    background-color: #0D0D0D;
    border: none;
    transition: color 2s;
    transition: background-color 1s;
    color: #D2D2D2;
    cursor: pointer;
}

.TopPageButton:hover{
    background-color: transparent;
    color: #0D0D0D;
}

.TopPageButton:hover .gradient-text{
    background: #0D0D0D;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.ButtonBackground{
    background-image: linear-gradient(to left, #8E2DE2, #FFA751 );
    width: 232px;
    height: 48px;
    border-radius: 50px;
    padding: 2px;
}
.TopPageRight{
    width: 50%;
    height: 200px;
    background-color: #8E2DE2;
    float: right;
}
.ServicePage{
    display: flex;
    flex-direction: column;
}
.ContainerServices{
    display: flex;
    align-items: center;
}
.CenterContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
.Testing{
    width: 100px;
    height: 100px;
    display: block;
    background-color: #8E2DE2;
}

#mobile{
    display: none;
}

@media screen and (max-width: 1500px){
    .TopPageBackground{
      width: 50%;
    }

@media screen and (max-width: 1200px){
    .TopPageH2{
        font-size: 36px;
    }
    #TopPageH22{
        font-size: 42px;
    }
    .TopPageH3{
        font-size: 15px;
    }
}    
    
  }

@media screen and (max-width: 1200px){
    .TopPageBackground{
        display: none;
    }
    .TopPageLeft{
        width: 100%;
        height: 540px;
        margin: 10px;
        position: relative;
    }
    .TopPageTextContainer{
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      
    }
    .TopPage{
        height: 700px;
    }
    
    #TopPageH22{
        margin-top: 60px;
    }
    .mobileBackground{
        display: block;
        position: absolute;
        width: 100%;
        max-height: 750px;
    }
    .TopPageEmpty{
        height: 70px;
    }
    .CenterContainer{
        display: none;
    }
    #mobile{
        display: flex;
        align-items: center;
    }

}

@media screen and (max-width: 876px){
    #mobile{
        display: block;
        overflow-x: auto;
    }
}
