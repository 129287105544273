
.OuterExpand{
    transition:width 0.4s ease-in-out;
    -webkit-transition:width 0.4s ease-in-out;
    height: 480px;
    width: 365px;
    background-color: #E0E0E0;
    margin:auto;
    border-radius: 25px;
}
.OuterExpand:hover{
    width: 575px;
}
.OuterExpand:hover .Expanding {
    width: 50%;
}
.Expanding{
    position: relative;
    transition:width 0.4s ease-in-out;
    -webkit-transition:width 0.4s ease-in-out;
    width: 365px;
    height: 100%;
    border-radius: 25px;
    background-color: rgb(146, 127, 255);  
}
.textOuter{
    width: 287px;
    height: 100%;
    background-color: #E0E0E0;
    margin: auto; 
    border-radius: 25px;
    float: right;
    transition:width 0.4s ease-in-out;
    -webkit-transition:width 0.4s ease-in-out;
    
}
.textInner{
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 70%;
    height: 80%;
    margin: auto;
    font-size: 16px;
    line-height: 25px;
}
.Picture{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}



