
.backgroundCard{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Card{
    margin: auto;
    border-radius: 25px;
    width: 370px;
    height: 480px;
    transform: rotate(-15deg);
    overflow: hidden;
}
.card4{
    position: relative;
    float: right;
}
.text{
    position: relative;
    width: 80%;
    height: 30%;
    display: flex;
    align-items: flex-end;
    margin: auto;
    text-align: left;
    
}
#text{
    width: 70%;
}
.h3Card{
    font-size: 30px;
    font-family: 'Space Grotesk', sans-serif;
}
#Card1{
    background-image: url('../graphics/step1.png');
}
#Card2{
    background-image: url('../graphics/step2.png');
}
#Card3{
    background-image: url('../graphics/step3.png');
}
#Card4{
    background-image: url('../graphics/step4.png');
}
.Vpath{
    position: absolute;
    top: 470px;
}
.circle{
    position: absolute;
    top: 573px;
    z-index: 20;
}
.vector{
    position: relative;
    top: 63px;
    z-index: 10;
}

.vector-mobile{
    display: none;
}

@media screen and (max-width: 1200px) {
    .vector{
        display: none;
    }
    .vector-mobile{
        display: none;
    }
    .container-roadmap-mobile{
        display: flex;
        flex-direction: row;
    }
    .backgroundCard{
        margin-bottom: 50px;
    }
    .Vpath{
        display: none;
    }
    .circle{
        position: relative;
    }
}