.GlobalContainer{
    position: relative;
    top: 138px;
    margin: auto;
    width: 1140px;
    display: flex;
    flex-direction: column;
}

#PortfolioContainer{
    width: 1340px;
    top: 338px;
}

.textH2{
    color: #D2D2D2;
    font-size: 40px;
    margin-bottom: 5px;
}
.textH3{
    color:#D2D2D2;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px;
}
.textP{
    color:#D2D2D2;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 30px;
}
.ProcessContainer{
    display: flex;
    flex-direction: row;
}
.centeredText{   
    text-align: center;
}
.InfoContainer{
    margin-top: 70px;
    display: flex;
    margin-bottom: 70px;
}
.LeftContactInfo{
    width: 500px;
    height: 200px;
}
.RightContactInfo{
    width: 100%;
    height: 200px;
}
.Input{
    width: 500px;
    height: 45px;
    background-color: #D2D2D2;
    border-radius: 50px;
    border: none;
    margin-bottom: 30px;
    padding: 0 20px;
    font-size: 15px;
}
.Input::placeholder{
    color: #717171;
}


.formContainerInner{
    display: flex;
    flex-direction: row;
}


#TextArea{
    transition: ease-in-out 0.3s;
    display: block;
    width: 1100px;
    height: 150px;
    padding: 5px 20px;
    border-radius: 25px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#TextAreaNone{
    transition: ease-in-out 0.3s;
    display: none;
    width: 1100px;
    height: 150px;
    padding: 5px 20px;
    border-radius: 25px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
 

  .CheckboxRoot {
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px var(--blackA7);
  }
  .CheckboxIndicator {
    color: var(--violet11);
  }
  
  .Label {
    color: white;
    padding-left: 15px;
    font-size: 13px;
    line-height: 20px;
  }

  .formButton{
    width: 113px;
    height: 45px;
    border-radius: 50px;

  }

  #SendButton{
    width: 144px;
    height: 37px;
    border-radius: 50px;
    border: none;
    background-color: #0D0D0D;
    color: #D2D2D2;
    float: right;
    position: relative;
    top: 4px;
    left: 4px;
    margin: 0;
  }

#ButtonToRight{
    float: left;
    margin-left:160px;
}

#JakDzialamy{
  height: 850px;
  text-align: center;
  margin-top: 150px;
}
.errorInformation{
    position: relative;
    color:red;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 21px;
    bottom: 20px;
    left: 10px;
}
#checkingBox{
   margin-top:0;
   margin-bottom: 0;
   height: fit-content;
   width: 600px;
   top: 40px;
}

.technologyStack{
    padding: 30px;
}

#animationBackground{
    background-color: aliceblue;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 50%;
    display: none;
    width: 100%;
    height:100%;
  
}

.gradient-text {
    background: linear-gradient(to right, #FFA751,
    #8E2DE2);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  
  }

.faqs{
    width: 90%;
    max-width: 1140px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 20px;
    color: #0D0D0D;
    position: relative;
    
}

.faqs .faq {
    margin: 15px;
    background-color: #D2D2D2;
    border-radius: 15px;
    padding: 15px;
    
    
}
.faqs .faq .faq-question{
    position: relative;
    font-size: 18px;
    padding-right: 80px;
    transition: all 0.4s ease;
    width: 90%;
    margin: 0 auto;
    
}

@media screen and (max-width: 930px){

    .faqs .faq {
        padding-right: 25px;
    }

}
@media screen and (max-width: 780px){

    .faqs .faq {
        padding-right: 40px;
    }

}
@media screen and (max-width: 580px){

    .faqs .faq {
        padding-right: 55px;
    }

}
@media screen and (max-width: 460px){

    .faqs .faq {
        padding-right: 70px;
    }

}

.faqs .faq .faq-question::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-image: url('../graphics/add_circle.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease-out;
}


.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
}



.faqs .faq.open .faq-question{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after{
    transform : translateY(-50%) rotate(180deg);
    top: 110%;
    transition: ease-in-out 0.4s;
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    width: 97%;
    margin: 0 auto;
    line-height: 26px;
}
.card {

    margin-bottom: 1rem;
}
.p-fileupload{
    background-color: #D2D2D2;
    width: 1080px;
    position: relative;
    border-radius: 50px;
    padding: 30px;
}
.p-button {
     width: 144px;
     height: 37px;
     border-radius: 50px;
     border: none;
     background-color: #0D0D0D;
     color: #D2D2D2;
     margin-left: 20px;
}
.p-fileupload-choose{
    background-color:#D2D2D2 ;
    color: #0D0D0D;
    margin-left: 0px;
    
}
.p-disabled{
    background-color: #717171;
}

.field{
    display: block;
}

.path-connect{
    display: none;
}

.circle2{
    display: none;
}

.service-together-mobile{
    display: none;
}

@media screen and (max-width: 1200px){
    .ProcessContainer{
        flex-direction: column;
        width: 100vw;
    }
    #mobileH2{
        margin-left: 10vw;
    }
    .Vpath{
        display: none;
    }
    .circle{
         display:none;
    }
    .circle2{
        display: none;
    }
    .vector{
        display: none;
    }

    .path-connect{
        display: none;    
    }

    .service-together-container{
        flex-direction: column;
    }

    .GlobalContainer{
        width: 93%;
    }
    .containerOne{
        margin-bottom: 50px;
    }
    .service-together-desktop{
        display: none;
    }

    .service-together-mobile{
        display: block;
    }
}

.image-big-button{
    width: 500px;
}

@media screen and (max-width: 1200px){

    .formContainerInner{
        flex-direction: column;
    }

    #TextArea{
        width: 90%;
    }
    #TextAreaNone{
        width: 90%;
    }

    .p-fileupload{
        width: 90%;
    }

    @media screen and (max-width: 585px) {
        #ButtonToRight{
            
            margin-left: 10px;
            margin-top: 65px;
        }
    }
   
    .InfoContainer{
        flex-direction: column;

    }
    
    .leftContactInfo{
        position: relative;
        left:50%;
        transform: translate(-50%);
    }
    .image-big-button{
        margin: auto;
        width: 90%;
    }
    .RightContactInfo{
        margin:auto;
        margin-top: 70px;
    }
} 

@media screen and (max-width:550px){
    .image-big-button{
        width: 90%;
    }
}



.mobile-portfolio{
    display: none;
}
 
@media screen and (max-width: 950px){
    .mobile-portfolio{
        display: block;
    }
    #PortfolioContainer{
        display: none;
    }
} 


.big-button-mobile{
    display: none;
}

@media screen and (max-width: 550px ) {
    .big-button-mobile{
        display: block;
    }
    .big-button-desktop{
        display: none;
    }
}


.portfolio-background-studio-tatuazu{
    background-image: url(../graphics/project_head.png);
    background-position: center;
    background-size: cover;
}
.portfolio-background-doktor-ewa{
    background-image: url(../graphics/project_head-ewa.png);
    background-position: center;
    background-size: cover;
}

.portfolio-background-rock-and-roll{
    background-image: url(../graphics/project_head-rock-and-roll.png);
    background-position: center;
    background-size: cover;
}

.portfolio-background-pdr-partner{
    background-image: url(../graphics/project_head-PDR-Partner.png);
    background-position: center;
    background-size: cover;
}

.portfolio-background-oslo-vest-bygg{
    background-image: url(../graphics/project_head-oslo.png);
    background-position: center;
    background-size: cover;
}

.portfolio-background-recolte{
    background-image: url(../graphics/project_head-recolte.png);
    background-position: center;
    background-size: cover;
}
.portfolio-half{
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    margin: auto;
    margin-top: 200px;
}


.portfolio-half .section1{
    width: 50%;
}

.portfolio-half .section2{
    width: 50%;

}

@media screen and (max-width: 1130px) {
    .portfolio-half{
        flex-direction: column;
    }
    
    .portfolio-half .section1{
        width: 100%;
        max-width: 1080px;
    }
    
    .portfolio-half .section2{
        width: 100%;
        max-width: 1080px;
    }
    
}

.portfolio-button{
    background-color: #0D0D0D;
    height: 50px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    color: #ffffff;
    line-height: 80px;
    position: relative;
    left:25%;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {

    .portfolio-button{
        left: 0px;
        padding: 15px;
        padding-left: 55px;
        padding-right: 55px;
        font-size: 20px

    }
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../graphics/loading-screen.png);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-position: center;
    opacity: 1;
    transition: opacity 0.5s;
  }

.loading-screen.hidden{
    opacity: 0;
    pointer-events: none;
    transition: ease-out 0.5s;
}

#cta-button{
    font-size: 28px;
}

@media screen and (max-width:400px) {
   #cta-button{
    font-size: 20px;
} 
}

.switch-container{
    padding: 5px 20px;
    border-radius: 50px;
    margin-bottom:20px;
    max-width:500px;
}

#area-none{

}