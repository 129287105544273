html{
    font-family: 'Space Grotesk', sans-serif;
}
.MainFooter{
    margin-top: 100px;
    margin-bottom: 70px;
    background-color: #D2D2D2;
    width: 1140px;
    height: 555px;
    border-radius: 25px;
}
.parentSection{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.leftSide{
    float: left;
    width: 50%;
}
.rightSide{
    float: rigth;
    width: 50%;
}
.BottomComponenth3{
    margin-left: 8%; 
    margin-top: 60px;
    font-size: 32px;
}

.contactBox{
    margin-left: 8%;
    display: flexbox;
    flex-direction: row;
}
.textInput{
    width: 345px;
    height: 45px;
    border-radius: 50px;
    border: none;
    background-color: #9B9B9B;
    padding-left: 20px;

}
.textInput:focus{
    color: #d5d2d2;
    font-size: 16px;
}
.buttonInput{
    position: relative;
    width: 95px;
    height: 37px;
    background-color: #0D0D0D;
    border-radius: 50px;
    border: none;
    color: #d5d2d2;
    font-size: 16px;
    right: 100px;
    top: 1px;
}

::placeholder{
    color: #D2D2D2;
    font-size: 16px;
    position: relative;
    left: 0px;
}

table{
    table-layout: fixed;
    width: 90%;
    margin-top: 60px;
    float: right;
    margin-right: 8%;
}

th{
    width: 50%;
    text-align: left;
    line-height: 50px;
    font-size: 32px;
}
td{
    line-height: 40px;
    font-size: 20px;
}

.img_bottom{
    display: block;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}
.img_mobile{
    display: none;
}
.line{
    margin: auto;
    margin-top: 15px;
    width: 80%;
    height: 1px;
    background-color:  #9B9B9B;
}
h4{
    text-align: center;
    color:#9B9B9B;
    font-size: 12px;
}
a{
    text-decoration: none;
    color:#0D0D0D;
}

#bottomMobile{
    height: 850px;
}


@media screen and (max-width: 1200px){
    .parentSection{
        flex-direction: column;
    }
    .MainFooter{
        height: 1000px;
        width: 100%;
    }
    .img_bottom{
        display: none;
    }
    .img_mobile{
        display: block;
        width: 80%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
    }
    #bottomMobile{
        height: 950px;
    }
    .rightSide{
        width: 100%;
    }
    .leftSide{
        width: 90%;
    }
   
}

@media screen and (max-width: 600px){
    .leftSide{
        width: 100%;
    }
    .textInput{
        width: 70%;
        font-size: 13px;
        margin-left: 15px;
    }
    .contactBox{
        margin-left: 5px;
    }
    .buttonInput{
        width: 76px;
        right: 80px;
        
    }
    
}

@media screen and (max-width: 413px){
    th{
        font-size: 23px;
    }
    td{
        font-size: 15px;
    }
}

@media screen and (max-width:411px) {
    .textInput{
        width: 65%;
    }
}

@media screen and (max-width: 375px){
    ::placeholder{
        font-size: 14px;
    }
    .buttonInput{
        font-size: 14px;
        top: 0px;
        width: 70px;
        right: 76px;
    }
}

@media screen and (max-width: 356px){
    ::placeholder{
        font-size: 12px;
    }

}

@media screen and (max-width:335px){
    .buttonInput{
        font-size: 13px;
        width: 65px;
        right: 70px;
    }
}