.menu{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0,0,0,0));
    height: 138px;
    position: fixed;
    width: 100%;
    z-index: 10;
}


@media screen and (max-width: 1200px){
    .menu{
        height: 110px;
    }
}

.link_menu{
    float: right;
    margin-right: 100px;
    position: relative;
    top: 47px;
    color: #9B9B9B;
    z-index: 10;
    transition: color 1s;
    cursor: pointer;
}
.link_menu_right{
    float: right;
    margin-right: 300px;
    position: relative;
    top: 47px;
    color: #9B9B9B;
    z-index: 10;
    transition: color 1s;
    cursor: pointer;
}

.logo_menu{
    position: fixed;
    width: 116px;
    height: 23px;
    top: 47px;
    left: 50px;
    z-index: 10;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .logo_menu{
        top: 35px;
    }
}


.link_menu:hover{
    color: #686868;
}
.link_menu_right:hover{
    color: #686868;
}
.mobileNav{
    display: none;
}

.fa-time{
    display: block;
    position: relative;
    
    background-image: url("../graphics/mobile/menuclose.png");
    width: 30px;
    height: 30px;
    float: right;
    right: 70px;
    top: 35px;
    
}
.fa-bars{
    display: block;
    position: relative;
    background-image: url("../graphics/mobile/menuopen.png");
    width: 26px;
    height: 16.5px;
    float: right;
    right: 80px;
    top: 35px;
}

.mobile-menu{
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    background-image: linear-gradient(to top, rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0.60) 90%, rgba(0, 0, 0, 0.45) 95%  , rgba(0, 0, 0, 0));
    top: 100px;
}

#link-menu-mobile{
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    float: left;
    left: 50%;
    transform: translate(-50%);
    top: 100px;
    margin-bottom: 70px;
    font-size: 30px;
    color: white;
}


@media screen and (max-width: 1200px){
    .mobileNav{
        display: block;
        
    }
    .desktopNav{
        display: none;
    }

}

