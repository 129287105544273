.containerOne{
    margin: 10px;
  
}

.containerThree{
    margin: 10px;
}

.containerTwo{
    margin: 10px;
}


.containerOneSmall{
    position: relative;
    margin: auto;
    bottom: 130px;
    margin-bottom: 240px;
}
.containerThreeSmall{
    position: relative;
    margin: auto;
    margin-bottom: 240px;
    bottom: 238px;
}

.containerTwoSmall{
    position: relative;
    margin: auto;
    margin-bottom: 240px;
}

@media screen and (max-width: 1200px){
    .containerOne{
        margin-bottom: 60px;
        margin-top: 50px;
    }
    .containerTwo{
        margin-bottom: 60px;
    }
    .containerThree{
        margin-bottom: 60px;
    }
}